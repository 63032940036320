<template>
  <router-view :key="$route.fullPath" />
</template>

<script>
export default {
  name: 'Setting',
  beforeRouteEnter (to, from, next) {
    next()
  }
}
</script>

<style scoped></style>
